<template>
  <div id="contacts-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      通訊錄
    </h2>

    <div>
      <div class="box grid grid-cols-12 gap-6 mt-5 p-5">

        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center ">
          <div class="flex flex-wrap sm:flex-nowrap items-center justify-end mb-2 sm:mb-0">
            <button v-if="!downloading" class="btn btn-primary shadow-md mr-2" @click="onDownload('pdf')">下載PDF
            </button>
            <button v-if="!downloading" class="btn btn-primary shadow-md" @click="openSelectColumnsModal('csv')">
              下載Excel
            </button>
            <span v-show="downloading"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>

          <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ contacts.length !== 0 ? from : 0 }} 至
            {{ to ?? 0 }} 個聯絡人
          </div>

          <div class="w-56 relative text-slate-500">
            <input v-model="search" type="text" class="form-control w-56 pr-10" placeholder="搜尋">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass"
                               class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0"/>
          </div>
        </div>

        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center justify-end">
          <div class="flex flex-wrap sm:flex-nowrap justify-end">
            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">分站</label>
              <select id="site" v-model="siteId" class="form-select mr-2" aria-label="請選擇分站">
                <option v-for="(item, key) in sites" :key="key" :value="item.site_id">{{ item.name }}</option>
              </select>
            </div>

            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">生效</label>
              <select id="site" v-model="isEffective" class="form-select mr-2" aria-label="請選擇">
                <option :value="true">是</option>
                <option :value="false">否</option>
              </select>
            </div>

            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">主任級或以上</label>
              <select id="site" v-model="isHighLevel" class="form-select mr-2" aria-label="請選擇">
                <option value="">全部</option>
                <option :value="true">是</option>
                <option :value="false">否</option>
              </select>
            </div>

            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-32">
              <label class="form-label text-xs">服務單位</label>
              <select id="application-types" v-model="centerId" class="form-select mr-2" aria-label="請選擇">
                <option value="">全部</option>
                <option v-for="(item, key) in centers" :key="key" :value="item.centre_id">{{ item.abbrev }}</option>
              </select>
            </div>
          </div>
        </div>

        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto overflow-visible">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="whitespace-nowrap">職員編號</th>
              <th class="whitespace-nowrap">英文全名</th>
              <th class="whitespace-nowrap">中文全名</th>
              <th class="whitespace-nowrap">電郵地址</th>
              <th class="whitespace-nowrap">服務單位</th>
              <th class="whitespace-nowrap">職位名稱</th>
              <th class="whitespace-nowrap">辦公室電話</th>
              <th class="whitespace-nowrap">手提電話</th>
              <th class="text-center whitespace-nowrap">檢視</th>
              <!--              <th v-if="checkPermission(permissions, 'site.phone_directory.edit') || checkPermission(permissions, 'site.phone_directory.delete')" class="text-center whitespace-nowrap">動作</th>-->
            </tr>
            </thead>
            <tbody>
            <tr class="" v-for="(item, key) in contacts" :key="key">
              <td>{{ item.staff_number ?? '-' }}</td>
              <td>{{ item.user.full_name ?? '-' }}</td>
              <td>{{ item.user.full_name_zh ?? '-' }}</td>
              <td>{{ item.user.email ?? '-' }}</td>
              <td>{{ item.centre ? item.centre.abbrev : '-' }}</td>
              <td>{{ item.position ?? '-' }}</td>
              <td>{{ item.user.office_number ?? '-' }}</td>
              <td>{{ item.user.contact_number ?? '-' }}</td>
              <td class="table-report__action text-center">
                <router-link :to="`/address-books/${item.contact_id}`" class="btn btn-success">
                  <font-awesome-icon icon="fa-regular fa-eye" class="text-white"/>
                </router-link>
              </td>
              <!--              <td v-if="checkPermission(permissions, 'site.phone_directory.edit') || checkPermission(permissions, 'site.phone_directory.delete')" class="table-report__action w-56">-->
              <!--                <div class="flex justify-center items-center">-->
              <!--                  <router-link v-if="checkPermission(permissions, 'site.phone_directory.edit')" :to="`/update-contact/${item.contact_id}`" class="flex items-center mr-3">-->
              <!--                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />-->
              <!--                    &nbsp;更改 </router-link>-->
              <!--                </div>-->
              <!--              </td>-->
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage"
                        :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->
      </div>
    </div>

    <!-- BEGIN: Select Columns Modal -->
    <div id="select-columns-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">
              選擇下載Excel欄
            </h2>
          </div>

          <div class="modal-body p-0">
            <div class="p-5">
              <div>
                <span class="text-danger">* </span>如全不選擇則視為全都下載
              </div>

              <div class="text-slate-500">
                <div v-for="(item, key) in columns" :key="key"
                     class="flex justify-between items-center px-3 py-2 mt-2 rounded-md">
                  <div class="form-switch flex items-center">
                    <label class="pointer flex">
                      <input type="checkbox" class="form-check-input mr-2" v-model="selectedColumns"
                             :value="item.value">
                      <div>{{ item.key }}</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
              </button>
              <button type="button" class="btn btn-primary w-24" @click="onDownload('csv')">下載</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Select Columns Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'allSites', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,

      sites: [],
      centers: [],

      contacts: [],

      downloading: false,

      siteId: null,
      isEffective: true,
      isHighLevel: '',
      centerId: '',
      itemPerPage: 10,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      search: '',

      columns: [
        {
          value: 'department',
          key: '部門'
        },
        {
          value: 'name',
          key: '姓名'
        },
        {
          value: 'position',
          key: '職位'
        },
        {
          value: 'email',
          key: '電郵'
        },
        {
          value: 'tel',
          key: '電話'
        },
        {
          value: 'fax',
          key: '傳真'
        }
      ],

      selectedColumns: []
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    siteId() {
      this.loadContacts()
    },
    isEffective() {
      this.loadContacts()
    },
    isHighLevel() {
      this.loadContacts()
    },
    centerId() {
      this.loadContacts()
    },
    currentPage(val) {
      this.loadContacts(false)
    },
    itemPerPage(val) {
      this.loadContacts()
    },
    search(val) {
      this.loadContacts()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
    window.tailwind.svgLoader()
  },
  methods: {
    async init() {
      const siteId = this.$route.query.siteId
      if (siteId != null && siteId !== '') {
        this.siteId = siteId
      } else if (this.currentSite) {
        this.siteId = this.currentSite.site_id
      }

      this.search = this.$route.query.search ?? ''

      await this.loadSites()
      await this.loadCenters()
      await this.loadContacts()

      this.mounted = true
    },
    async loadSites() {
      this.sites = await this.getSites()
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadContacts(resetCurrentPage = true) {
      const response = await this.getSiteContacts(this.siteId, this.search, this.itemPerPage, this.currentPage, this.currentStatus, this.isEffective, this.isHighLevel, this.centerId)
      this.contacts = response.data
      this.pages = response.meta.page.last_page
      this.from = response.meta.page.from
      this.to = response.meta.page.to

      if (resetCurrentPage) {
        this.currentPage = 1
      }
    },
    openSelectColumnsModal() {
      const el = document.querySelector('#select-columns-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDownload(type) {
      this.downloading = true
      const response = await this.downloadMailingList(type, this.selectedColumns)
      const blob = new Blob([response], { type: `application/${type}` })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      if (type === 'csv') {
        link.download = this.$moment().format('YYYY_MM_DD') + '_CSD_Mailing_List.xlsx'
      } else {
        link.download = this.$moment().format('YYYY_MM_DD') + `_CSD_Mailing_List.${type}`
      }
      link.click()
      URL.revokeObjectURL(link.href)
      this.downloading = false

      if (type === 'csv') {
        const el = document.querySelector('#select-columns-modal')
        const modal = window.tailwind.Modal.getOrCreateInstance(el)
        modal.hide()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#contacts-page {
  .tab-content {
    background-color: #FFF;

    table {
      thead {
        th:first-child, th:nth-child(3) {
          width: 120px;
        }
      }
    }
  }
}
</style>
