<template>
  <div id="user-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label for="staff-number" class="form-label"><span class="text-danger">*</span> 職員編號</label>
                <input v-model="form.staff_number" id="staff-number" type="text" class="form-control w-full" placeholder="請輸入職員編號">
                <div v-if="'staff_number' in errors" class="text-danger mt-2" v-html="errors.staff_number"></div>
              </div>

              <div>
                <label class="form-label"><span class="text-danger">*</span> 用戶登入名稱</label>
                <input v-model="form.username" type="text" class="form-control w-full" placeholder="請輸入用戶登入名稱" :disabled="mode === 'update'">
                <div v-if="'username' in errors" class="text-danger mt-2" v-html="errors.username"></div>
              </div>
            </div>
          </div>

          <div class="mt-3" v-if="mode === 'create'">
            <label for="password" class="form-label"><span class="text-danger">*</span> 密碼</label>
            <input v-model="form.password" id="password" type="password" class="form-control w-full" placeholder="請輸入密碼">
            <div v-if="'password' in errors" class="text-danger mt-2" v-html="errors.password"></div>
          </div>

          <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
            <span class="font-medium">基本資料</span>
          </div>

          <div>
            <div class="flex justify-between align-items-center mb-1">
              <label class="form-label">大頭圖像</label>
              <button type="button" class="btn btn-danger" v-if="form.avatar_base64 != null" @click="onRemoveImage">
                移除
              </button>
            </div>

            <form data-single="true" action="/" class="dropzone">
              <div class="fallback"><input name="file" type="file"/></div>
              <div class="dz-message" data-dz-message>
                <div class="text-lg font-medium">將圖片拖放到此處或單擊以上傳</div>
                <div class="text-slate-500"> 支援檔案：JPG, JPEG, PNG, BMP, GIF</div>
                <div class="text-slate-500"> 圖片尺吋：735x725 (300ppi)</div>
                <div class="text-slate-500"> 檔案大小：不小於1MB</div>
              </div>
            </form>
            <div v-if="'avatar_base64' in errors" class="text-danger mt-2" v-html="errors.avatar_base64"></div>
          </div>

          <div class="mt-5">
            <div class="sm:grid grid-cols-2 gap-2">
              <div>
                <label for="centre_id" class="form-label"><span class="text-danger">*</span> 所屬部/組/服務單位</label>
                <select id="centre_id" v-model="form.centre_id" class="form-select" aria-label="請選擇所屬部/組/服務單位">
                  <option value="">--- 請選擇 ---</option>
                  <option v-for="(item, key) in centers" :key="key" :value="item.centre_id">{{ item.abbrev }}
                    {{ item.name_zh }}
                  </option>
                </select>
                <div v-if="'centre_id' in errors" class="text-danger mt-2" v-html="errors.centre_id"></div>
              </div>

              <div>
                <label for="position_id" class="form-label"><span class="text-danger">*</span> 職位</label>
                <select id="position_id" v-model="form.position_id" class="form-select" aria-label="請選擇職位">
                  <option value="">--- 請選擇 ---</option>
                  <option v-for="(item, key) in positions" :key="key" :value="item.position_id">{{ item.name }}</option>
                </select>
                <div v-if="'position_id' in errors" class="text-danger mt-2" v-html="errors.position_id"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label class="form-label">主任級或以上</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_high_level" type="checkbox" class="form-check-input">
                </div>
                <div v-if="'is_high_level' in errors" class="text-danger mt-2" v-html="errors.is_high_level"></div>
              </div>
              <div class="flex-1 ml-1">
                <label class="form-label">註冊社工</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_register_social_worker" type="checkbox" class="form-check-input">
                </div>
                <div v-if="'is_register_social_worker' in errors" class="text-danger mt-2"
                     v-html="errors.is_register_social_worker"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label for="honorific_title" class="form-label"><span class="text-danger">*</span> 稱謂</label>
            <select id="honorific_title" v-model="form.honorific_title" class="form-select" aria-label="請選擇稱呼">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in titles" :key="key" :value="item.value">{{ item.key }}</option>
            </select>
            <div v-if="'honorific_title' in errors" class="text-danger mt-2" v-html="errors.honorific_title"></div>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="last_name_zh" class="form-label"><span class="text-danger">*</span> 中文姓氏</label>
                <input v-model="form.last_name_zh" id="last_name_zh" type="text" class="form-control w-full"
                       placeholder="請輸入姓氏">
                <div v-if="'last_name_zh' in errors" class="text-danger mt-2" v-html="errors.last_name_zh"></div>
              </div>
              <div class="flex-1">
                <label for="first_name_zh" class="form-label"><span class="text-danger">*</span> 中文名字</label>
                <input v-model="form.first_name_zh" id="first_name_zh" type="text" class="form-control w-full"
                       placeholder="請輸入名字">
                <div v-if="'first_name_zh' in errors" class="text-danger mt-2" v-html="errors.first_name_zh"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="last_name" class="form-label"><span class="text-danger">*</span> 英文姓氏</label>
                <input v-model="form.last_name" id="last_name" type="text" class="form-control w-full"
                       placeholder="請輸入姓氏">
                <div v-if="'last_name' in errors" class="text-danger mt-2" v-html="errors.last_name"></div>
              </div>
              <div class="flex-1 mr-1">
                <label for="first_name" class="form-label"><span class="text-danger">*</span> 英文名字</label>
                <input v-model="form.first_name" id="first_name" type="text" class="form-control w-full"
                       placeholder="請輸入名字">
                <div v-if="'first_name' in errors" class="text-danger mt-2" v-html="errors.first_name"></div>
              </div>
              <div class="flex-1">
                <label for="other_name_en" class="form-label">英文別名</label>
                <input v-model="form.other_name_en" id="other_name_en" type="text" class="form-control w-full"
                       placeholder="請輸入別名">
                <div v-if="'other_name_en' in errors" class="text-danger mt-2" v-html="errors.other_name_en"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label for="display_name" class="form-label">顯示名稱</label>
            <input v-model="form.display_name" id="display_name" type="text" class="form-control w-full"
                   placeholder="請輸入顯示名稱" readonly>
            <div v-if="'display_name' in errors" class="text-danger mt-2" v-html="errors.display_name"></div>
          </div>

          <div class="mt-3">
            <label for="email" class="form-label"><span class="text-danger">*</span> 電郵地址</label>
            <input v-model="form.email" id="email" type="email" class="form-control w-full"
                   placeholder="請輸入電郵地址">
            <div v-if="'email' in errors" class="text-danger mt-2" v-html="errors.email"></div>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="office_number" class="form-label"><span class="text-danger">*</span> 辦公室電話</label>
                <input v-model="form.office_number" id="office_number" type="text" class="form-control w-full"
                       placeholder="請輸入辦公室電話" @keypress="isSlashOrNumber($event)"
                       @keyup="formatPhone('office_number', $event)">
                <div v-if="'office_number' in errors" class="text-danger mt-2" v-html="errors.office_number"></div>
              </div>

              <div class="flex-1">
                <label for="fax" class="form-label"><span class="text-danger">*</span> 傳真</label>
                <input v-model="form.fax" id="fax" type="text" class="form-control w-full" placeholder="請輸入傳真"
                       @keypress="isSlashOrNumber($event)" @keyup="formatPhone('fax', $event)">
                <div v-if="'fax' in errors" class="text-danger mt-2" v-html="errors.fax"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="contact-number" class="form-label">手提電話</label>
                <input v-model="form.contact_number" id="contact_number" type="text" class="form-control w-full"
                       placeholder="請輸入手提電話" @keypress="isSlashOrNumber($event)"
                       @keyup="formatPhone('contact_number', $event)">
                <div v-if="'contact_number' in errors" class="text-danger mt-2" v-html="errors.contact_number"></div>
              </div>

              <div class="flex-1">
                <label for="other_number" class="form-label">其他電話</label>
                <input v-model="form.other_number" id="other_number" type="text" class="form-control w-full"
                       placeholder="請輸入其他電話" @keypress="isSlashOrNumber($event)"
                       @keyup="formatPhone('other_number', $event)">
                <div v-if="'other_number' in errors" class="text-danger mt-2" v-html="errors.other_number"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label for="birthdate" class="form-label">出生日期</label>
            <div id="birthdate">
              <date-picker v-model="birthdate" type="date" placeholder="請選擇出生日期"
                           input-class="form-control w-full" :disabled-date="notAfterDate"></date-picker>
            </div>
            <div v-if="'birthdate' in errors" class="text-danger mt-2" v-html="errors.birthdate"></div>
          </div>

          <div class="mt-3">
            <label for="special_skill" class="form-label">專長</label>
            <input v-model="form.special_skill" id="special_skill" type="text" class="form-control w-full"
                   placeholder="請輸入專長">
            <div v-if="'special_skill' in errors" class="text-danger mt-2" v-html="errors.special_skill"></div>
          </div>

          <div class="mt-3">
            <label for="about_me" class="form-label">關於我</label>
            <input v-model="form.about_me" id="about_me" type="text" class="form-control w-full"
                   placeholder="請輸入關於我">
            <div v-if="'about_me' in errors" class="text-danger mt-2" v-html="errors.about_me"></div>
          </div>

          <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
            <span class="font-medium">其他資料</span>
          </div>

          <div class="mt-3">
            <label for="personal_website" class="form-label">個人網站</label>
            <input v-model="form.personal_website" id="personal_website" type="text" class="form-control w-full"
                   placeholder="請輸入個人網站">
            <div v-if="'personal_website' in errors" class="text-danger mt-2" v-html="errors.personal_website"></div>
          </div>

          <div class="mt-3">
            <label for="blog_website" class="form-label">博客網站</label>
            <input v-model="form.blog_website" id="blog_website" type="text" class="form-control w-full"
                   placeholder="請輸入博客網站">
            <div v-if="'blog_website' in errors" class="text-danger mt-2" v-html="errors.blog_website"></div>
          </div>

          <div class="mt-3">
            <label for="other_website" class="form-label">其他網站</label>
            <input v-model="form.other_website" id="other_website" type="text" class="form-control w-full"
                   placeholder="請輸入其他網站">
            <div v-if="'other_website' in errors" class="text-danger mt-2" v-html="errors.other_website"></div>
          </div>

          <div class="mt-3">
            <label for="group" class="form-label">團體及組織</label>
            <input v-model="form.group" id="group" type="text" class="form-control w-full"
                   placeholder="請輸入團體及組織">
            <div v-if="'group' in errors" class="text-danger mt-2" v-html="errors.group"></div>
          </div>

          <div class="mt-3">
            <label for="honors" class="form-label">榮譽及獎項</label>
            <input v-model="form.honors" id="honors" type="text" class="form-control w-full"
                   placeholder="請輸入榮譽及獎項">
            <div v-if="'honors' in errors" class="text-danger mt-2" v-html="errors.honors"></div>
          </div>

          <div class="mt-3">
            <label for="interest" class="form-label">興趣</label>
            <input v-model="form.interest" id="interest" type="text" class="form-control w-full"
                   placeholder="請輸入興趣">
            <div v-if="'interest' in errors" class="text-danger mt-2" v-html="errors.interest"></div>
          </div>

          <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
            <span class="font-medium">教育</span>
          </div>

          <div class="mt-3">
            <label for="school" class="form-label">就讀學校</label>
            <input v-model="form.school" id="school" type="text" class="form-control w-full"
                   placeholder="請輸入就讀學校">
            <div v-if="'school' in errors" class="text-danger mt-2" v-html="errors.school"></div>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="academic" class="form-label">獲取的學歷</label>
                <input v-model="form.academic" id="academic" type="text" class="form-control w-full"
                       placeholder="請輸入獲取的學歷">
                <div v-if="'academic' in errors" class="text-danger mt-2" v-html="errors.academic"></div>
              </div>

              <div class="flex-1 mr-1">
                <label for="academic_department" class="form-label">學系</label>
                <input v-model="form.academic_department" id="academic_department" type="text"
                       class="form-control w-full" placeholder="請輸入學系">
                <div v-if="'academic_department' in errors" class="text-danger mt-2"
                     v-html="errors.academic_department"></div>
              </div>

              <div class="flex-1">
                <label for="academic_other" class="form-label">其他資料</label>
                <input v-model="form.academic_other" id="academic_other" type="text" class="form-control w-full"
                       placeholder="請輸入其他資料">
                <div v-if="'academic_other' in errors" class="text-danger mt-2" v-html="errors.academic_other"></div>
              </div>
            </div>
          </div>

          <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
            <span class="font-medium">專業資格</span>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="professional_qualifications" class="form-label">持有的專業資格</label>
                <input v-model="form.professional_qualifications" id="professional_qualifications" type="text"
                       class="form-control w-full" placeholder="請輸入持有的專業資格">
                <div v-if="'professional_qualifications' in errors" class="text-danger mt-2"
                     v-html="errors.professional_qualifications"></div>
              </div>

              <div class="flex-1 mr-1">
                <label for="qualification_authority" class="form-label">頒發機構全名</label>
                <input v-model="form.qualification_authority" id="qualification_authority" type="text"
                       class="form-control w-full" placeholder="請輸入頒發機構全名">
                <div v-if="'qualification_authority' in errors" class="text-danger mt-2"
                     v-html="errors.qualification_authority"></div>
              </div>

              <div class="flex-1">
                <label for="qualification_level" class="form-label">已達到的程度</label>
                <input v-model="form.qualification_level" id="qualification_level" type="text"
                       class="form-control w-full" placeholder="請輸入已達到的程度">
                <div v-if="'qualification_level' in errors" class="text-danger mt-2"
                     v-html="errors.qualification_level"></div>
              </div>
            </div>
          </div>

          <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
            <span class="font-medium">工作經驗</span>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="past_company" class="form-label">公司名稱</label>
                <input v-model="form.past_company" id="past_company" type="text" class="form-control w-full"
                       placeholder="請輸入公司名稱">
                <div v-if="'past_company' in errors" class="text-danger mt-2" v-html="errors.past_company"></div>
              </div>

              <div class="flex-1 mr-1">
                <label for="past_position" class="form-label">職位</label>
                <input v-model="form.past_position" id="past_position" type="text" class="form-control w-full"
                       placeholder="請輸入職位">
                <div v-if="'past_position' in errors" class="text-danger mt-2" v-html="errors.past_position"></div>
              </div>

              <div class="flex-1">
                <label for="past_intro" class="form-label">描述</label>
                <input v-model="form.past_intro" id="past_intro" type="text" class="form-control w-full"
                       placeholder="請輸入描述">
                <div v-if="'past_intro' in errors" class="text-danger mt-2" v-html="errors.past_intro"></div>
              </div>
            </div>
          </div>

          <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
            <span class="font-medium">通訊錄</span>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label class="form-label">打印在Mailing List PDF</label>
                <div class="form-switch mt-2">
                  <input v-model="form.is_show_in_contactlist" type="checkbox" class="form-check-input">
                </div>
                <div v-if="'is_show_in_contactlist' in errors" class="text-danger mt-2" v-html="errors.is_show_in_contactlist"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="contactlist_order" class="form-label">打印次序</label>
                <input v-model="form.contactlist_order" id="contactlist_order" type="text" class="form-control w-full"
                       placeholder="請輸入打印次序">
                <div v-if="'contactlist_order' in errors" class="text-danger mt-2" v-html="errors.contactlist_order"></div>
              </div>

              <div class="flex-1">
                <label for="contactlist_site_id" class="form-label">顯示分站</label>
                <select id="contactlist_site_id" v-model="form.contactlist_site_id" class="form-select" aria-label="請選擇服務">
                  <option value="">--- 請選擇 ---</option>
                  <option v-for="(item, key) in contactlistSites" :key="key" :value="item.site_id">{{
                      item.name
                    }}
                  </option>
                </select>
                <div v-if="'contactlist_site_id' in errors" class="text-danger mt-2" v-html="errors.contactlist_site_id"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label for="contactlist_remarks" class="form-label">其他打印備註</label>
            <textarea v-model="form.contactlist_remarks" id="contactlist_remarks" type="text" class="form-control w-full"
                   placeholder="請輸入其他打印備註"></textarea>
            <div v-if="'contactlist_remarks' in errors" class="text-danger mt-2" v-html="errors.contactlist_remarks"></div>
          </div>

          <div class="col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6 mt-6" role="alert">
            <span class="font-medium">其他</span>
          </div>

          <div class="mt-3">
            <label for="note" class="form-label">備註欄</label>
            <input v-model="form.note" id="note" type="text" class="form-control w-full" placeholder="請輸入備註">
            <div v-if="'note' in errors" class="text-danger mt-2" v-html="errors.note"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/management/users" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      mode: 'create',

      birthdate: null,

      contactlistSites: [],

      form: {
        staff_number: null,
        username: null,
        password: null,

        avatar_base64: null,
        centre_id: '',
        position_id: '',
        is_high_level: false,
        is_register_social_worker: false,

        honorific_title: '',
        first_name_zh: null,
        last_name_zh: null,
        first_name: null,
        last_name: null,
        other_name_en: null,
        display_name: null,
        email: null,
        office_number: null,
        fax: null,
        contact_number: null,
        other_number: null,
        birthdate: null,
        special_skill: null,
        about_me: null,

        personal_website: null,
        blog_website: null,
        other_website: null,
        group: null,
        honors: null,
        interest: null,
        school: null,
        academic: null,
        academic_department: null,
        academic_other: null,
        professional_qualifications: null,
        qualification_authority: null,
        qualification_level: null,
        past_company: null,
        past_position: null,
        past_intro: null,
        note: null,

        is_show_in_contactlist: false,
        contactlist_order: null,
        contactlist_site_id: null,
        contactlist_remarks: null
      },

      errors: {},
      loading: false,
      finish: false,

      positions: [],
      centers: []
    }
  },
  watch: {
    async 'form.centre_id'(val) {
      this.setDisplayName()
    },
    async 'form.position_id'(val) {
      this.setDisplayName()
    },
    async 'form.first_name'() {
      this.$set(this.form, 'first_name', this.form.first_name.toUpperCase())
      this.setDisplayName()
    },
    async 'form.last_name'() {
      this.$set(this.form, 'last_name', this.form.last_name.toUpperCase())
      this.setDisplayName()
    },
    async 'form.other_name_en'() {
      this.$set(this.form, 'other_name_en', this.form.other_name_en.toUpperCase())
      this.setDisplayName()
    },
    data(val) {
      if (val != null) {
        this.init()
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    await this.init()

    this.$nextTick(() => {
      this.dropZone()

      const self = this

      this.$dz.on('addedfile', function(file) {
        while (this.files.length > this.options.maxFiles) this.removeFile(this.files[0])

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function() {
          self.$set(self.form, 'avatar_base64', reader.result)
        }
      })
    })
  },
  methods: {
    async init() {
      await this.loadCenters()
      await this.loadPositions()
      await this.loadContactlistSites()

      if (this.data != null) {
        this.form = this.data
        this.form.last_name = this.form.last_name == null ? '' : this.form.last_name
        this.form.first_name = this.form.first_name == null ? '' : this.form.first_name
        this.form.last_name_en = this.form.last_name_en == null ? '' : this.form.last_name_en
        this.form.first_name_en = this.form.first_name_en == null ? '' : this.form.first_name_en
        this.form.other_name_en = this.form.other_name_en == null ? '' : this.form.other_name_en
        this.birthdate = this.form.birthdate != null ? this.$moment(this.form.birthdate).toDate() : null
        this.form.centre_id = this.form.centre_id == null ? '' : this.form.centre_id
        this.form.position_id = this.form.position_id == null || this.form.position_id === 0 ? '' : this.form.position_id
        this.form.honorific_title = this.form.honorific_title == null || this.form.honorific_title === 0 ? '' : this.form.honorific_title
        this.mode = 'update'
      }
    },
    onRemoveImage() {
      this.$dz.removeAllFiles()
      this.$set(this.form, 'avatar_base64', null)
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadPositions() {
      this.positions = await this.getPositions()
    },
    async loadContactlistSites() {
      this.contactlistSites = await this.getSites('')
    },
    setDisplayName() {
      const firstName = this.form.first_name ?? ''
      const lastName = this.form.last_name ?? ''
      const otherName = this.form.other_name_en ?? ''
      let fullName = otherName == null || otherName === '' ? `${lastName} ${firstName}` : `${otherName} ${lastName}`
      fullName = fullName.toUpperCase()
      const centerAbbrev = this.getObjectValue(this.centers, 'centre_id', this.form.centre_id, 'abbrev')
      const positionName = this.getObjectValue(this.positions, 'position_id', this.form.position_id, 'name').toUpperCase()
      const displayName = `${fullName} [${centerAbbrev} - ${positionName}]`
      this.$set(this.form, 'display_name', displayName)
    },
    notAfterDate(date) {
      const now = new Date()
      return date > new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
    async onSave() {
      this.loading = true
      this.errors = {}

      if (this.birthdate != null) {
        this.form.birthdate = this.$moment(this.birthdate).format('YYYY-MM-DD')
      }

      let response

      if (this.mode === 'create') {
        response = await this.createUser(this.form)
      } else {
        response = await this.updateUser(this.data.user_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增用戶' : '已成功更新用戶',
          message: '請稍後，將會轉至用戶頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/management/users')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增用戶' : '未能更新用戶',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    },
    formatPhone(field, event) {
      if (this.form[field] != null && this.form[field].length >= 4 && !this.form[field].includes(' ') && event.key !== 'Backspace') {
        const values = this.split(this.form[field], 4)
        this.$set(this.form, field, `${values[0]} ${values[1]}`)
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
