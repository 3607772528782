<template>
  <div id="create-data-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      新增橫額
    </h2>
    <banner-form v-if="checkPermission(permissions, 'site.banner.create')" :auth="auth" :current-site="currentSite" :permissions="permissions"></banner-form>
    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      setTimeout(() => {
        this.mounted = true
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped></style>
